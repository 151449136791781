<template>
  
	<nav id="side-panel-tabs">
		<ul class="tabs-rec">
			<li v-for="tab in tabs" :key="tab.id" v-bind:class="{ active: tab.isActive }" @click="tabSelect(tab)"><span :class="tab.icon"></span> {{ tab.title }}</li>
		</ul>
	</nav>
		
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'sidenav',
  data () {
    return {
      tabs: [
        {
          title: 'Template',
          icon: 'icon-pencil',
          isActive: true
        },
        {
          title: 'Modules',
          icon: 'icon-th-large',
          isActive: false
        }
      ]
    }
  },
  methods: {
    tabSelect (thistab) {
      this.tabs.forEach(function (tab) {
        if (tab.title === thistab.title) {
          tab.isActive = true
        } else {
          tab.isActive = false
        }
      })
      eventHub.$emit('sidetab-selected', thistab.title)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
