<template>

       <header class="main-panel-header">
        <ul id="home-tab-menu" class="tabs-rec">
          <li v-for="tab in tablist" :key="tab.id" @click="selectTab(tab.name)" v-bind:class="{ active: tab.selected }"><span :class="tab.icon"></span> {{ tab.title }}</li>
        </ul>
      </header>
 
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'browsetabs',
  data () {
    return {
      tablist: [
        {
          name: 'filelist',
          title: 'Open File',
          icon: 'icon-edit-alt',
          selected: true
        },
        {
          name: 'templatelist',
          title: 'Create New',
          icon: 'icon-plus',
          selected: false
        }
      ]
    }
  },
  methods: {
    selectTab (thistab) {
      this.tablist.forEach(function (tab) {
        if (tab.name === thistab) {
          tab.selected = true
        } else {
          tab.selected = false
        }
      })
      eventHub.$emit('tab-selected', thistab)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
