<template>
  
	<section class="main-header-option">
    <div class="content-wrap">
      <input class="main-header-option-text" type="text" v-model="title">
      <ul class="main-header-option-menu nav-inline">
        <li @click="deletePress()">Delete</li>
        <!-- <li>Save As</li> -->
        <li class="save-btn btn-boxed" @click="saveData()">Save<span class="btn-icon">&#9662;</span></li>
      </ul>
    </div>
  </section>
		
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
import router from '../router'
export default {
  name: 'composeheader',
  data () {
    return {
      title: ''
    }
  },
  methods: {
    showModal (obj) {
      // obj {title: '', message: ''}
      eventHub.$emit('show-modal', obj)
    },
    updateData () {
      // console.log(JSON.parse(localStorage.emailData).post_title)
      this.title = JSON.parse(localStorage.emailData).post_title
    },
    deletePress () {
      this.showModal({ type: 'confirm', title: 'Please confirm', message: 'Are you sure you want to delete [ ' + this.title + ' ] ?', action: 'delete-post' })
    },
    deletePost () {
      eventHub.$emit('show-loader', true)
      var maildata = JSON.parse(localStorage.getItem('emailData'))
      var mailid = {}
      var self = this
      mailid.id = maildata.id
      globalMethods.postAuthData('/wp-json/metro/v1/saveemaildata', mailid)
        .then(function () {
          eventHub.$emit('hide-loader')
          router.push({name: 'Browse', params: { user: self.$route.params.user }})
        })
    },
    saveData () {
      eventHub.$emit('show-loader', true)
      var sendData = JSON.parse(localStorage.getItem('emailData'))
      sendData.post_title = this.title
      sendData.post_content = btoa(sendData.post_content)
      console.log(sendData.post_content)
      globalMethods.postAuthData('/wp-json/metro/v1/saveemaildata', sendData)
        .then(function (response) {
          var result = response.data
          console.log(result)
          eventHub.$emit('hide-loader')
          eventHub.$emit('emaildata-updated')
          eventHub.$emit('emaildata-saved')
        })
    }
  },
  watch: {
    title: function () {
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      emailData.post_title = this.title
      console.log(emailData.post_title)
      localStorage.setItem('emailData', JSON.stringify(emailData))
    }
  },
  mounted: function () {
    eventHub.$on('emaildata-loaded', () => {
      this.updateData()
    })
    eventHub.$on('emaildata-updated', () => {
      this.updateData()
    })
    eventHub.$on('delete-post', () => {
      this.deletePost()
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
