<template>
  <div class="compose">

	<header class="main-header">
      <mainheader></mainheader>
      <composeheader></composeheader>
    </header>

	<main class="main-content">

		<section class="main-panel">
			<div class="main-panel-wrap">
				
				<panelheader></panelheader>
				
				<texteditor></texteditor>
				
				<htmleditor></htmleditor>
				
				<panelcomposer></panelcomposer>
				
			</div>
		</section>
		<aside class="side-panel">
			<div class="side-panel-wrap">
				
				<sidenav></sidenav>
				
				<optionspanel></optionspanel>

				<modulepanel></modulepanel>

			</div>
		</aside>
	</main>
  
  <modalbox></modalbox>
  <loader></loader>

</div>
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'Compose',
  data () {
    return {
      user: this.$route.params.user,
      emailid: this.$route.params.email,
      workspace: this.$route.params.workspace
    }
  },
  methods: {
    getEmailData () {
      // var self = this
      globalMethods.getData('/wp-json/metro/v1/getemaildata', {email: this.emailid})
      .then(function (response) {
        localStorage.setItem('emailData', response.data)
        // console.log(JSON.parse(response.data).subject)
        eventHub.$emit('emaildata-loaded')
        eventHub.$emit('hide-loader')
      })
    },
    getModuleSections () {
      globalMethods.getData('/wp-json/metro/v1/getmodulesections')
      .then(function (response) {
        localStorage.setItem('moduleSections', response.data)
        console.log(JSON.parse(response.data))
        eventHub.$emit('modulesections-loaded')
      })
    }
  },
  mounted: function () {
    eventHub.$on('emaildata-saved', () => {
      this.getEmailData()
    })
    eventHub.$emit('show-loader')
    eventHub.$emit('auth-user')
    this.getEmailData()
    this.getModuleSections()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
