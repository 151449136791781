<template>
  
	<div v-show="isActive" class="side-tab-panel side-panel-content">
					<div class="side-panel-content-wrap">
						
						<breadcrumbs></breadcrumbs>
						
						<templateoptions></templateoptions>
            
            <moduleoptions></moduleoptions>
						
					</div>

				</div>
		
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'optionspanel',
  data () {
    return {
      name: 'Template',
      isActive: true
    }
  },
  mounted: function () {
    eventHub.$on('sidetab-selected', (thistab) => {
      if (thistab === this.name) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
