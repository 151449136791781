<template>
  
	<div v-show="isActive" id="main-composer" class="main-panel-composer main-panel-tab">
		<iframe id="composer-frame" src="/composerframe.html" width="100%" height="100%" frameborder="0"></iframe>
	</div>
		
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'panelcomposer',
  data () {
    return {
      name: 'composer',
      isActive: true,
      firstlaunch: true
    }
  },
  methods: {
    setComposerContent () {
      var composerData = JSON.parse(localStorage.getItem('emailData')).post_content
      var composerDocument = window.frames['composer-frame'].contentDocument
      composerDocument.open()
      composerDocument.write(composerData)
      composerDocument.close()
      this.addComposerScripts()
      console.log('composer')
    },
    updateComposerModule (option) {
      var composerDocument = window.frames['composer-frame'].contentDocument
      composerDocument.getElementById(option.id).innerHTML = option.value
      this.updateHtmlCode()
    },
    updateHtmlCode () {
      var composerFrame = document.getElementById('composer-frame')
      composerFrame.contentWindow.cleanHTML()
      // var composerCode = new XMLSerializer().serializeToString(composerFrame.contentDocument)
      // composerCode = composerCode.replace(/\u00A0/g, '&nbsp;')
      // composerFrame.contentWindow.refresh(true)
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      // emailData.post_content = composerCode
      emailData.post_content = composerFrame.contentWindow.cleanedhtml
      localStorage.setItem('emailData', JSON.stringify(emailData))
      eventHub.$emit('emaildata-updated', true)
    },
    addComposerScripts () {
      var composerDocument = window.frames['composer-frame'].contentDocument
      if (composerDocument.getElementById('composer-styles') === null) {
        var styles = composerDocument.createElement('link')
        styles.id = 'composer-styles'
        styles.type = 'text/css'
        styles.rel = 'stylesheet'
        styles.href = '/css/composer.css'
        composerDocument.head.appendChild(styles)
      }
      /* if (composerDocument.getElementById('jquery') === null) {
        var script1 = composerDocument.createElement('script')
        script1.id = 'jquery'
        script1.type = 'text/javascript'
        script1.src = '/js/jquery-3.2.1.min.js'
        composerDocument.head.appendChild(script1)
      }
      if (composerDocument.getElementById('jquery-ui') === null) {
        var script2 = composerDocument.createElement('script')
        script2.id = 'jquery-ui'
        script2.type = 'text/javascript'
        script2.src = '/js/jquery-ui.min.js'
        composerDocument.head.appendChild(script2)
      } */
      if (composerDocument.getElementById('composer-script') === null) {
        var script3 = composerDocument.createElement('script')
        script3.id = 'composer-script'
        script3.type = 'text/javascript'
        script3.src = '/js/composer.js'
        composerDocument.head.appendChild(script3)
      }
    },
    addModuleSection (html) {
      var composerFrame = document.getElementById('composer-frame')
      composerFrame.contentWindow.addModuleSection(html)
    }
  },
  mounted: function () {
    eventHub.$emit('auth-user')
    eventHub.$on('deselect-module', () => {
      document.getElementById('composer-frame').contentWindow.deselectModule()
    })
    eventHub.$on('delete-module', (moduleid) => {
      document.getElementById('composer-frame').contentWindow.deleteModule(moduleid)
    })
    eventHub.$on('module-updated', (option) => {
      this.updateComposerModule(option)
    })
    eventHub.$on('composer-updated', () => {
      this.updateHtmlCode()
    })
    eventHub.$on('panel-selected', (thispanel) => {
      if (thispanel.select === this.name) {
        this.isActive = true
        if (this.firstlaunch === false) {
          this.setComposerContent()
        }
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('emaildata-loaded', (composerupdate) => {
      this.setComposerContent(composerupdate)
      this.firstlaunch = false
    })
    eventHub.$on('add-module-section', (html) => {
      this.addModuleSection(html)
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
