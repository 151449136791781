import Vue from 'vue'
import axios from 'axios'
import router from './router'
import eventHub from './EventHub.js'

window.userToken
window.apiHost = 'https://metro.keefstudio.com'

window.globalMethods = new Vue({
  name: 'globalMethods',
  methods: {
    getData (endpoint, dataobj) {
      if(dataobj) dataobj.timestamp = new Date().getTime()
      return axios({
        method: 'get',
        url: window.apiHost + endpoint,
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.userToken).token,
          'Content-Type': 'application/json'
        },
        params: dataobj
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
        eventHub.$emit('show-modal', {title: error.message, message: error.response.data.message})
      })
    },
    postAuthData (endpoint, dataobj) {
      if (typeof localStorage.userToken !== 'undefined') {
        return axios({
          method: 'post',
          url: window.apiHost + endpoint,
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.userToken).token,
            'Content-Type': 'application/json'
          },
          params: {timestamp: new Date().getTime()},
          data: dataobj
        })
        .then(function (response) {
          // console.log(response.data)
          return response
        })
        .catch(function (error) {
          console.log(error)
          //router.push('/')
          //eventHub.$emit('show-modal', {title: error.message, message: error.response.data.message})
        })
      } else {
        router.push('/')
      }
    },
    authUser () {
      this.postAuthData('/wp-json/jwt-auth/v1/token/validate')
      // console.log(JSON.parse(localStorage.userToken))
    }
  },
  created: function () {
    eventHub.$on('auth-user', () => {
      this.authUser()
    })
  }
})

export default window.globalMethods
