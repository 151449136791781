<template>
  <div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">{{ title }}</div>
			<div class="modal-content">
        {{ message }}
        <ul class="modal-nav nav-inline">
          <li class="btn-boxed" @click="hideModal()">Cancel</li>
          <li class="btn-boxed" @click="confirmAction(action)">OK</li>
        </ul>
      </div>
		</div>
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'modalconfirm',
  props: ['title', 'message', 'action'],
  data () {
    return {
      // titles: 'title',
      // messages: 'message'
    }
  },
  methods: {
    hideModal () {
      eventHub.$emit('hide-modal')
    },
    confirmAction (action) {
      eventHub.$emit('hide-modal')
      eventHub.$emit(action)
    }
  },
  mounted: function () {
  }
}
</script>
