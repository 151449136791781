<template>
  
	<ul id="template-breadcrumbs" class="side-panel-heading breadcrumb-nav cf">
		<li @click="deselectModule">Email Template</li>
		<li v-for="crumb in crumbs" :key="crumb.id">{{ crumb }}</li>
	</ul>
		
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'breadcrumbs',
  data () {
    return {
      crumbs: []
    }
  },
  methods: {
    moduleSelect (opts) {
      this.crumbs = [ opts.module_name ]
    },
    moduleDeselect () {
      this.crumbs = []
    },
    deselectModule () {
      eventHub.$emit('deselect-module')
    }
  },
  mounted: function () {
    eventHub.$on('module-selected', (opts) => {
      this.moduleSelect(opts)
    })
    eventHub.$on('module-deselected', () => {
      this.moduleDeselect()
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
