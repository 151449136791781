<template>
  <div class="home">

    <header class="main-header">
      <mainheader></mainheader>
      <browseheader></browseheader>
    </header>
	
  <main class="main-content">
    
    <folderpanel></folderpanel>
    
    <section class="main-panel">
      <div class="main-panel-wrap">
       
       <browsetabs></browsetabs>
       
        <div class="main-panel-content-scroll">
          
          <emaillist></emaillist>
    
          <templatelist></templatelist>
    
        </div>
      </div>
    </section>
  
  </main>
  
  <modalbox></modalbox>
  <loader></loader>

</div>
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'Browse',
  data () {
    return {
      msg: 'Test Template'
    }
  },
  mounted: function () {
    eventHub.$emit('auth-user')
    eventHub.$emit('show-loader', true)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
