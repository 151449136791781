<template>
  
	<div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">Send Test Message</div>
			<div class="modal-content">
        <p>{{ message }}</p>
        <br>
        <input type="email" placeholder="sample@email.com" v-model="emailaddress">
        <ul class="modal-nav nav-inline">
          <li class="btn-boxed" @click="hideModal()">Cancel</li>
          <li class="btn-boxed" @click="submit()">OK</li>
        </ul>
      </div>
		</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'templateactionsend',
  data () {
    return {
      message: 'Enter email address:',
      emailaddress: ''
    }
  },
  methods: {
    submit () {
      eventHub.$emit('hide-modal')
      eventHub.$emit('show-loader', true)
      globalMethods.postAuthData('/wp-json/metro/v1/sendtestemail', {address: this.emailaddress, emaildata: JSON.parse(localStorage.getItem('emailData'))})
        .then(function (response) {
          var result = response.data
          console.log(result)
          eventHub.$emit('hide-modal')
          eventHub.$emit('hide-loader')
          eventHub.$emit('email-sent')
        })
    },
    hideModal () {
      eventHub.$emit('hide-modal')
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
