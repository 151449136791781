import Vue from 'vue'

window.eventHub = new Vue({
  name: 'eventHub',
  methods: {
    broadcast (event, param) {
      this.$emit(event, param)
    }
  }
})

export default window.eventHub
