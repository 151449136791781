<template>
  
  <div v-show="isActive" class="side-tab-panel side-panel-content">
          <div class="side-panel-content-wrap">
            
            <div class="side-panel-modules">
              <div v-for="sectiontype in sections" :key="sectiontype.id" class="" @click.self="toggleExpanded">
                <h3 class="side-panel-heading">{{sectiontype.sectiontype}}</h3>
                <ul class="module-list">
                  <li v-for="section in sectiontype" :key="section.id">
                    <div class="module-title">{{section.post_title}}</div>
                    <div class="module-preview" v-html="section.thumb" @click="addModuleSection(section.post_content)"></div>
                    <!-- <ul class="module-controls">
                      <li class="icon-plus"></li>
                      <li class="icon-pencil"></li>
                      <li class="icon-download-alt"></li>
                    </ul> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'modulepanel',
  data () {
    return {
      name: 'Modules',
      sections: [],
      isActive: false
    }
  },
  methods: {
    toggleExpanded (event) {
      if (event.target.className !== 'section-expanded') {
        event.target.className = 'section-expanded'
      } else {
        event.target.className = ''
      }
    },
    addModuleSection (html) {
      eventHub.$emit('add-module-section', html)
    }
  },
  mounted: function () {
    eventHub.$on('sidetab-selected', (thistab) => {
      if (thistab === this.name) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('modulesections-loaded', () => {
      this.sections = JSON.parse(localStorage.getItem('moduleSections'))
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
