<template>
  
  <div class="side-panel-optionset">
    
    <ul class="optionset">
      <li>
        <span class="optionset-title">Folder:</span>
        <dropdown :menuitems="folders" :selected="folder.name" callback="selectFolder"></dropdown>
      </li>
      <li>
        <span class="optionset-title">Category:</span>
        <dropdown :menuitems="categories" :selected="category" callback="selectCategory"></dropdown>
      </li>
       <li>
        <span class="optionset-title">Status:</span>
        <dropdown :menuitems="statuses" :selected="status_name" callback="selectStatus"></dropdown>
      </li>
      <li>
        <span class="optionset-title">Subject line:</span>
        <input type="text" v-model="subject">
      </li>
      <li>
        <span class="optionset-title">Version:</span>
        <dropdown :menuitems="versions" :selected="version" emitter="version-select"></dropdown>
        <span class="optionset-btn" @click="versionRevert()">Revert</span>
      </li>
      <!--
      <li>
        <span class="optionset-title">Theme:</span>
        <dropdown :menuitems="themes" :selected="theme" callback="selectFolder"></dropdown>
      </li>
      
      -->
    </ul>
    
  </div>
    
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'templateoptions',
  data () {
    return {
      name: 'Template',
      isActive: true,
      folder: {},
      folders: [],
      category: '',
      categories: [],
      subject: '',
      statuses: [
        {name: 'Draft'},
        {name: 'Design approved'},
        {name: 'Compliance approved'},
        {name: 'Manageer approved'}
      ],
      status_name: '',
      versions: [],
      version: '',
      versionid: 'test'
    }
  },
  methods: {
    showModal (obj) {
      eventHub.$emit('show-modal', obj)
    },
    selectFolder (folder) {
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      emailData.folder = folder.name
      emailData.folder_id = folder.term_id
      localStorage.setItem('emailData', JSON.stringify(emailData))
      eventHub.$emit('emaildata-updated')
    },
    selectCategory (category) {
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      emailData.category = category.name
      emailData.category_id = category.term_id
      localStorage.setItem('emailData', JSON.stringify(emailData))
      eventHub.$emit('emaildata-updated')
    },
    selectStatus (status) {
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      emailData.status_name = status.name
      emailData.status_id = status.term_id
      localStorage.setItem('emailData', JSON.stringify(emailData))
      eventHub.$emit('emaildata-updated')
    },
    setVersion (v) {
      this.versionid = v.id
    },
    updateData () {
      this.folder = JSON.parse(localStorage.getItem('folder'))
      this.folders = JSON.parse(localStorage.getItem('folders'))
      this.category = JSON.parse(localStorage.emailData).category
      this.categories = JSON.parse(localStorage.getItem('emailData')).categories
      this.subject = JSON.parse(localStorage.emailData).subject
      this.status_name = JSON.parse(localStorage.emailData).status_name
      this.statuses = JSON.parse(localStorage.getItem('emailData')).statuses
      this.versions = JSON.parse(localStorage.getItem('emailData')).versions
      if (this.versions) {
        this.version = this.versions[0].name
      }
      // console.log(JSON.stringify(this.versions))
    },
    versionRevert () {
      this.showModal({ type: 'confirm', title: 'Please confirm', message: 'Are you sure you want to revert the content of this email?', action: 'revert-version' })
    },
    revertVersion () {
      console.log(this.versionid)
      for (var i = 0; i < this.versions.length; i++) {
        if (this.versions[i]['id'].toString() === this.versionid.toString()) {
          var emailData = JSON.parse(localStorage.getItem('emailData'))
          emailData.post_content = this.versions[i]['content']
          localStorage.setItem('emailData', JSON.stringify(emailData))
          eventHub.$emit('emaildata-loaded')
        }
      }
    }
  },
  watch: {
    subject: function () {
      var emailData = JSON.parse(localStorage.getItem('emailData'))
      emailData.subject = this.subject
      localStorage.setItem('emailData', JSON.stringify(emailData))
    }
  },
  mounted: function () {
    eventHub.$on('emaildata-loaded', () => {
      this.updateData()
    })
    eventHub.$on('emaildata-updated', () => {
      this.updateData()
    })
    eventHub.$on('version-select', (obj) => {
      this.setVersion(obj)
    })
    eventHub.$on('revert-version', () => {
      this.revertVersion()
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
