// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.component('mainheader', require('./components/Main.Header.vue').default)
Vue.component('modalbox', require('./components/Main.ModalBox.vue').default)
Vue.component('loader', require('./components/Main.Loader.vue').default)
Vue.component('dropdown', require('./components/Main.Dropdown.Menu.vue').default)

Vue.component('browseheader', require('./components/Browse.Header.vue').default)
Vue.component('folderpanel', require('./components/Browse.FolderPanel.vue').default)
Vue.component('browsetabs', require('./components/Browse.Tabs.vue').default)
Vue.component('emaillist', require('./components/Browse.EmailList.vue').default)
Vue.component('templatelist', require('./components/Browse.TemplateList.vue').default)

Vue.component('composeheader', require('./components/Compose.Header.vue').default)
Vue.component('panelheader', require('./components/Compose.PanelHeader.vue').default)
Vue.component('htmleditor', require('./components/Compose.PanelHTML.vue').default)
Vue.component('texteditor', require('./components/Compose.PanelText.vue').default)
Vue.component('panelcomposer', require('./components/Compose.PanelComposer.vue').default)
Vue.component('sidenav', require('./components/Compose.SideNav.vue').default)
Vue.component('optionspanel', require('./components/Compose.OptionsPanel.vue').default)
Vue.component('modulepanel', require('./components/Compose.ModulePanel.vue').default)
Vue.component('templateoptions', require('./components/Compose.TemplateOptions.vue').default)
Vue.component('moduleoptions', require('./components/Compose.ModuleOptions.vue').default)
Vue.component('breadcrumbs', require('./components/Compose.Breadcrumbs.vue').default)
Vue.component('editor', require('vue2-ace-editor'))

Vue.component('modalmessage', require('./components/Modal.Message.vue').default)
Vue.component('modalconfirm', require('./components/Modal.Confirm.vue').default)
Vue.component('folderactioncreate', require('./components/Modal.Folder.Action.Create.vue').default)
Vue.component('folderactionrename', require('./components/Modal.Folder.Action.Rename.vue').default)
Vue.component('folderactiondelete', require('./components/Modal.Folder.Action.Delete.vue').default)
Vue.component('templateactionsend', require('./components/Modal.Template.Action.Send.vue').default)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  name: 'vueapp',
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  },
  methods: {
  }
})
