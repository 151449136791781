<template>
  
	<div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">Rename Folder</div>
			<div class="modal-content">
        <p>{{ message }}</p>
        <br>
        <dropdown :menuitems="folders" :selected="folder.name" callback=""></dropdown>&nbsp;<input ref="fn" type="text" placeholder="New name" v-model="foldername">
          <ul class="modal-nav nav-inline">
          <li class="btn-boxed" @click="hideModal()">Cancel</li>
          <li class="btn-boxed" @click="submit()">OK</li>
        </ul>
      </div>
		</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'folderactionrename',
  data () {
    return {
      message: 'Rename existing folder?',
      folder: JSON.parse(localStorage.getItem('folder')),
      folders: JSON.parse(localStorage.getItem('folders')),
      foldername: ''
    }
  },
  methods: {
    submit () {
      if (this.foldername !== '') {
        eventHub.$emit('hide-modal')
        eventHub.$emit('show-loader', true)
        globalMethods.postAuthData('/wp-json/metro/v1/renameworkspacefolder', {folder: JSON.parse(localStorage.folder).term_id, newname: this.foldername})
          .then(function (response) {
            var result = response.data
            console.log(result)
            var folder = JSON.parse(localStorage.getItem('folder'))
            folder.name = result
            localStorage.setItem('folder', JSON.stringify(folder))
            eventHub.$emit('hide-modal')
            eventHub.$emit('folder-created')
          })
      } else {
        this.$nextTick(() => this.$refs.fn.focus())
      }
      // console.log(JSON.parse(localStorage.getItem('folder')))
    },
    updateData () {
      this.folder = JSON.parse(localStorage.getItem('folder'))
      this.folders = JSON.parse(localStorage.getItem('folders'))
    },
    hideModal () {
      eventHub.$emit('hide-modal')
    }
  },
  mounted: function () {
    eventHub.$on('folder-selected', () => {
      this.updateData()
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
