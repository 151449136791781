<template>
  
	<div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">Delete Folder</div>
			<div class="modal-content">
        <p>{{ message }}</p>
        <br>
          <dropdown :menuitems="folders" :selected="folder.name" callback=""></dropdown>
          <br><br><br>
          <input type="checkbox"> Yes, I'm sure
        <ul class="modal-nav nav-inline">
          <li class="btn-boxed" @click="hideModal()">Cancel</li>
          <li class="btn-boxed" @click="confirmAction(action)">OK</li>
        </ul>
      </div>
		</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'folderactiondelete',
  data () {
    return {
      message: 'Are you sure want to permanently delete this folder and containing files?',
      folder: JSON.parse(localStorage.getItem('folder')),
      folders: JSON.parse(localStorage.getItem('folders'))
    }
  },
  methods: {
    updateData () {
      this.folder = JSON.parse(localStorage.getItem('folder'))
      this.folders = JSON.parse(localStorage.getItem('folders'))
    },
    hideModal () {
      eventHub.$emit('hide-modal')
    }
  },
  mounted: function () {
    eventHub.$on('folder-selected', () => {
      this.updateData()
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
