<template>
  
  <div v-show="isActive" id="main-editor" class="main-panel-editor main-panel-tab">
    <editor v-model="htmldata" width="100%" height="100%" lang="html" @init="initEditor"></editor>
  </div>
    
</template>

<script>
import eventHub from '../EventHub'
// import editor from 'vue2-ace-editor'
export default {
  name: 'htmleditor',
  data () {
    return {
      codeEditor: Object,
      panel: 'htmleditor',
      isActive: false,
      htmldata: '',
      emailData: {}
    }
  },
  methods: {
    initEditor () {
      require('brace/mode/html')
      require('brace/theme/dreamweaver')
      require('brace/theme/chrome')
    },
    showPanel (thispanel) {
      if (thispanel.select === this.panel) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      eventHub.$emit('deselect-module')
    }
  },
  watch: {
    htmldata: function () {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.emailData.post_content = this.htmldata
      localStorage.setItem('emailData', JSON.stringify(this.emailData))
      eventHub.$emit('emaildata-updated')
    }
  },
  mounted: function () {
    eventHub.$on('panel-selected', (thistab) => {
      this.showPanel(thistab)
    })
    eventHub.$on('emaildata-loaded', () => {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.htmldata = this.emailData.post_content
    })
    eventHub.$on('emaildata-updated', () => {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.htmldata = this.emailData.post_content
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
