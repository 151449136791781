<template>
  
    <div v-show="this.isActive" class="side-panel-editables">
        <div class="side-panel-optionset">
            <ul class="optionset">
                <li v-for="option in editables" :key="option.id">
                  <div v-if="option.name === 'title'">
                    <span  class="optionset-title">{{option.name}}</span>
                    <textarea class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                  <div v-if="option.name === 'text'">
                    <span class="optionset-title">{{option.name}}</span>
                    <textarea class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                  <div v-if="option.name === 'button'">
                    <span class="optionset-title">{{option.name}}</span>
                    <textarea class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                  <div v-if="option.name === 'link'">
                    <span class="optionset-title">{{option.name}}</span>
                    <textarea class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                  <div v-if="option.name === 'paragraph'">
                    <span class="optionset-title">{{option.name}}</span>
                    <textarea class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                  <div v-if="option.name === 'list-item'">
                    <span class="optionset-title">{{option.name}}</span>
                    <textarea v-if="option.name === 'list-item'" class="optionset-textarea" type="text" v-model="option.value" v-on:change="inputChange(option)"></textarea>
                  </div>
                </li>
            </ul>
            <ul class="optionset-tools">
              <!-- <li><span class="icon-export"></span>Duplicate</li>  -->
              <li @click="deletePress"><span class="icon-cancel"></span>Remove</li>
            </ul>
        </div>
    </div>
        
</template>

<script>
import eventHub from '../EventHub'

export default {
  name: 'moduleoptions',
  data () {
    return {
      name: 'moduleoptions',
      editables: {},
      isActive: false,
      editor: 'test',
      options: {
        menubar: false
      },
      moduleid: '',
      currMod: {},
      moduleSelected: false,
      currTab: 'Template'
    }
  },
  methods: {
    showModal (obj) {
      eventHub.$emit('show-modal', obj)
    },
    moduleSelect (opts) {
      this.currMod = opts
      this.moduleid = opts.module_id
      this.editables = opts.module_editables
      this.moduleSelected = true
      if (this.currTab === 'Template') {
        this.isActive = true
      }
    },
    moduleDeselect () {
      this.isActive = false
      this.moduleSelected = false
    },
    moduleDelete () {
      eventHub.$emit('delete-module', this.moduleid)
    },
    deletePress () {
      this.showModal({ type: 'confirm', title: 'Please confirm', message: 'Are you sure you want to delete the selected section?', action: 'module-delete' })
    },
    inputChange (option) {
      eventHub.$emit('module-updated', option)
    },
    resizeTextarea () {
      var elements = document.getElementsByTagName('textarea')
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.height = 'auto'
        elements[i].style.height = elements[i].scrollHeight + 'px'
      }
    }
  },
  mounted: function () {
    eventHub.$on('sidetab-selected', (thistab) => {
      this.currTab = thistab
      if (thistab === 'Template' && this.moduleSelected === true) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('panel-selected', (thispanel) => {
      if (thispanel === this.name) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('module-selected', (opts) => {
      this.moduleSelect(opts)
    })
    eventHub.$on('module-deselected', () => {
      this.moduleDeselect()
    })
    eventHub.$on('module-delete', () => {
      this.moduleDelete()
    })
  },
  updated: function () {
    this.resizeTextarea()
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
