<template>

        <div v-show="isActive" class="main-panel-content tab-panel">
          <ul class="template-list">
            <!--<router-link :to="{ name: 'Compose', params: { user: username, workspace: workspace, email: template.id }}" tag="li" v-for="template in templates"><table class="template-preview"><tr><td></td></tr></table>{{ template.title }}</router-link>-->
            <li v-for="template in templates" :key="template.id" @click="createTemplateEmail(template.id)">
              <div class="template-wrap">
                {{ template.title }}
                <div class="template-preview">
                  <div class="template-thumb" v-html="template.thumb"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
 
</template>

<script>
import eventHub from '../EventHub'
import globalMethods from '../GlobalMethods.js'
import router from '../router'
export default {
  name: 'templatelist',
  data () {
    return {
      templates: [{title: ' ', id: '12345'}],
      isActive: false,
      name: 'templatelist',
      username: this.$route.params.user,
      workspace: 'test'
    }
  },
  methods: {
    getWorkspaceTemplates (workspaceid) {
      var self = this
      eventHub.$emit('show-loader')
      this.workspace = JSON.parse(localStorage.getItem('workspace')).slug
      globalMethods.getData('/wp-json/metro/v1/getworkspacetemplates', {workspace: workspaceid})
      .then(function (response) {
        var result = JSON.parse(response.data)
        self.templates = result
        eventHub.$emit('hide-loader')
      })
    },
    createTemplateEmail (templateid) {
      eventHub.$emit('show-loader', true)
      var self = this
      var data = {template: templateid, folder: JSON.parse(localStorage.folder).name}
      globalMethods.postAuthData('/wp-json/metro/v1/createtemplateemail', data)
        .then(function (response) {
          var result = response.data
          // console.log(result)
          // eventHub.$emit('hide-loader')
          router.push({name: 'Compose', params: {user: self.username, workspace: self.workspace, email: result}})
        })
    }
  },
  mounted: function () {
    eventHub.$on('tab-selected', (thistab) => {
      if (thistab === this.name) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('workspace-selected', (workspace) => {
      this.getWorkspaceTemplates(workspace)
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
