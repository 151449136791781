<template>
  
	<div v-show="isActive" class="modal" @click="hideModal()">
		
		<modalmessage v-if="type === 'message'" @click.native.stop></modalmessage>
		
		<modalconfirm v-if="type === 'confirm'" :title=title :message=message :action=action @click.native.stop></modalconfirm>
		
		<folderactioncreate v-if="type === 'create'" @click.native.stop></folderactioncreate>
		
		<folderactionrename v-if="type === 'rename'" @click.native.stop></folderactionrename>
		
		<folderactiondelete v-if="type === 'delete'" @click.native.stop></folderactiondelete>
		
		<templateactionsend v-if="type === 'sendtest'" @click.native.stop></templateactionsend>
		
	</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'modalbox',
  data () {
    return {
      type: 'message',
      title: 'Modal',
      message: 'Test Template',
      action: 'hide-modal',
      isActive: false
    }
  },
  methods: {
    hideModal () {
      this.isActive = false
      console.log('hideModal')
      // eventHub.$emit('show-loader')
    }
  },
  mounted: function () {
    eventHub.$on('show-modal', (obj) => {
      this.type = obj.type
      this.title = obj.title
      this.message = obj.message
      this.action = obj.action
      this.isActive = true
      console.log(this.type)
    })
    eventHub.$on('hide-modal', () => {
      this.hideModal()
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
