<template>
  
	<header class="main-panel-header">
		<!-- <ul id="main-panel-actions" class="menu-dropdown">
			<li>Actions<span class="btn-icon">&#9662;</span>
				<ul>
					<li>list item</li>
					<li>longer list item</li>
					<li>list item</li>
				</ul>
			</li>
		</ul> -->
		<dropdown :menuitems="actions" selected="Actions" :labeled="label" callback="templateAction"></dropdown>
		<ul id="main-panel-mode" class="tabs-pill">
			<li v-for="tab in tabs" :key="tab.id" v-bind:class="{ active: tab.isActive }" @click="selectTab(tab)">{{ tab.title }}</li>
		</ul>
	</header>
		
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'panelheader',
  data () {
    return {
      actions: [
        {
          name: 'Download PDF'
        },
        {
          name: 'Inline CSS'
        },
        {
          name: 'Send Test'
        }
      ],
      label: true,
      tabs: [
        {
          title: 'Composer',
          select: 'composer',
          isActive: true
        },
        {
          title: 'HTML',
          select: 'htmleditor',
          isActive: false
        },
        {
          title: 'Text',
          select: 'texteditor',
          isActive: false
        }
      ]
    }
  },
  methods: {
    templateAction (action) {
      this.listhover = false
      if (action.name === 'Send Test') {
        eventHub.$emit('show-modal', {type: 'sendtest', title: 'Send Test Message', message: 'Enter email address:'})
      }
    },
    selectTab (thistab) {
      this.tabs.forEach(function (tab) {
        if (thistab.title === tab.title) {
          tab.isActive = true
        } else {
          tab.isActive = false
        }
      })
      eventHub.$emit('panel-selected', thistab)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
