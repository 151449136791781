<template>
  
	<div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">Create New Folder</div>
			<div class="modal-content">
        <p>{{ message }}</p>
        <br>
          <input type="text" placeholder="Folder name" v-model="foldername">
          <ul class="modal-nav nav-inline">
          <li class="btn-boxed" @click="hideModal()">Cancel</li>
          <li class="btn-boxed" @click="submit()">OK</li>
        </ul>
      </div>
		</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'folderactioncreate',
  data () {
    return {
      message: 'Create new folder:',
      foldername: ''
    }
  },
  methods: {
    submit () {
      eventHub.$emit('hide-modal')
      eventHub.$emit('show-loader', true)
      globalMethods.postAuthData('/wp-json/metro/v1/createworkspacefolder', {folder: this.foldername, workspace: JSON.parse(localStorage.workspace).term_id})
        .then(function (response) {
          var result = response.data
          console.log(result)
          eventHub.$emit('hide-modal')
          eventHub.$emit('folder-created')
        })
    },
    hideModal () {
      eventHub.$emit('hide-modal')
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
