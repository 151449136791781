import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login.vue'
import Browse from '@/components/Browse.vue'
import Compose from '@/components/Compose.vue'

Vue.use(Router)

var router = new Router({
  routes: [
    {
      path: '/login',
      alias: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/:user/browse/',
      name: 'Browse',
      component: Browse,
      props: { user: 'test' }
    },
    {
      path: '/:user/:workspace/compose/:email',
      name: 'Compose',
      component: Compose,
      props: { user: 'test', email: '12345' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  console.log('routing')
  next()
})

export default router
