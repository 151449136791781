<template>
  
    <section class="main-header-admin cf">
      <div class="content-wrap">
        <!--<h1 class="main-header-brand"><router-link tag="a" :to="{ name: 'Browse', params: { user: username }}"><span class="icon-left-open-1 header-back-icon"></span><span class="icon-th-large header-brand-icon"></span> metro</router-link></h1>-->
        <h1 class="main-header-brand"><span class="icon-left-open-1 header-back-icon" @click="browseBack()"></span>StudioMail</h1>
        
        <nav class="main-header-menu">
          <ul class="nav-inline">
            <li @click="testAPI()">Admin</li>
            <li @click="logout()">Log out</li>
          </ul>
        </nav>
      </div>
    </section>
    
</template>

<script>
import globalMethods from '../GlobalMethods.js'
import eventHub from '../EventHub.js'
import router from '../router'
export default {
  name: 'mainheader',
  data () {
    return {
      username: this.$route.params.user
    }
  },
  methods: {
    testAPI () {
      globalMethods.postAuthData('/wp-json/wp/v2/posts/', {content: 'tester', title: 'sample test', excerpt: 'testing'})
    },
    browseBack () {
      eventHub.$emit('show-modal', { type: 'confirm', title: 'Please confirm', message: 'Are you sure you want to close this template? Any unsaved changes will be lost.', action: 'back-to-browse' })
    },
    backToBrowse () {
      router.push({name: 'Browse', params: { user: this.username }})
    },
    logout () {
      router.push({name: 'Login'})
    }
  },
  mounted: function () {
    eventHub.$on('back-to-browse', () => {
      this.backToBrowse()
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
