<template>
  <div class="login">
  
    <main class="login-main">

      <h1>metro<span class="icon-th-large"></span></h1>
      <h2>EMAIL STUDIO</h2>
      <form @submit="loginUser()">
        <ul class="login-form">
          <li><label>username:</label><br><input type="text" v-model="username" autocomplete="metro-username"></li>
          <li><label>password:</label><br><input type="password" v-model="password" autocomplete="metro-password"></li>
          <li><button type="submit" class="btn-submit">Submit<span v-show="submitting" class="icon-spin2 animate-spin"></span></button></li>
        </ul>
      </form>
      
      <p class="error" v-html="this.errorMsg"></p>
    
    </main>

  </div>
</template>

<script>
// import eventHub from '../EventHub.js'
import router from '../router'
import axios from 'axios'
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      errorMsg: '',
      submitting: false
    }
  },
  methods: {
    loginUser () {
      this.submitting = true
      var self = this
      axios({
        method: 'post',
        url: window.apiHost + '/wp-json/jwt-auth/v1/token',
        data: {
          username: this.username,
          password: this.password
        }
      })
      .then(function (response) {
        self.submitting = false
        // console.log(response.data)
        localStorage.setItem('userToken', JSON.stringify(response.data))
        router.push({name: 'Browse', params: { user: JSON.parse(localStorage.userToken).user_display_name }})
      })
      .catch(function (error) {
        self.submitting = false
        self.errorMsg = error.response.data.message
      })
    }
  },
  beforeMount: function () {
    localStorage.clear()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
