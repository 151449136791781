<template>
  <aside class="side-panel">
      <div class="side-panel-wrap">
        <header class="side-panel-header">
          <div class="folder-panel-title">
            <span class="icon-folder-1"></span> Folders:
          </div>
          <ul id="folder-panel-options" class="menu-dropdown" @click="listhover = !listhover" @mouseleave="listhover = false">
              <li>
                  <span class="btn-icon">&#9662;</span>
                <ul v-bind:class="{hover: listhover}">
                  <li @click="folderAction('create')">Create new</li>
                  <li @click="folderAction('rename')">Rename</li>
                  <!-- <li @click="folderAction('delete')">Delete</li> -->
                </ul>
              </li>
            </ul>
        </header>
        <div class="side-panel-content">
          <ul id="folder-menu-list" class="menu-list">
            <li v-for="folder in folderlist" :key="folder.id" v-bind:class="{'active': isSelected(folder)}" @click="selectFolder(folder)">
                {{ folder.name }}
            </li>
          </ul>
        </div>
      </div>
    </aside>
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'folderpanel',
  data () {
    return {
      folderlist: JSON.parse(localStorage.getItem('folders')),
      selected: JSON.parse(localStorage.getItem('folder')) || {term_id: ''},
      listhover: false
    }
  },
  methods: {
    isSelected (tab) {
      if (typeof tab.term_id !== 'undefined') {
        return Boolean(tab.term_id === this.selected.term_id)
      }
    },
    folderAction (action) {
      this.listhover = false
      eventHub.$emit('show-modal', {type: action, title: action, message: action})
    },
    selectFolder (folder) {
      if (this.selected.term_id !== folder.term_id) {
        this.selected = folder
        localStorage.setItem('folder', JSON.stringify(folder))
        eventHub.$emit('folder-selected', this.selected.term_id)
      }
    },
    getWorkspaceFolders (workspaceid) {
      var self = this
      eventHub.$emit('show-loader', true)
      globalMethods.getData('/wp-json/metro/v1/getworkspacefolders', {workspace: workspaceid})
      .then(function (response) {
        var match = false
        self.folderlist = JSON.parse(response.data)
        localStorage.setItem('folders', response.data)
        if (typeof self.selected !== 'undefined' && self.selected.term_id !== '') {
          for (var i = 0; i < self.folderlist.length; i++) {
            if (parseInt(self.folderlist[i].term_id) === parseInt(self.selected.term_id)) {
              self.selected = self.folderlist[i]
              eventHub.$emit('folder-selected', self.selected.term_id)
              match = true
              break
            }
          }
        }
        if (match === false) {
          self.selected = self.folderlist[0]
          localStorage.setItem('folder', JSON.stringify(self.selected))
          eventHub.$emit('folder-selected', self.selected.term_id)
        }
        console.log('folder match: ' + match)
      })
    }
  },
  mounted: function () {
    eventHub.$on('workspace-selected', (workspace) => {
      this.getWorkspaceFolders(workspace)
    })
    eventHub.$on('folder-created', () => {
      this.getWorkspaceFolders(JSON.parse(localStorage.workspace).term_id)
    })
    eventHub.$on('folder-renamed', () => {
      this.getWorkspaceFolders(JSON.parse(localStorage.workspace).term_id)
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
