<template>
  
		<section class="main-header-option">
          <div class="content-wrap">
           <div class="main-header-option-select">Workspace:&nbsp; 
              <!-- <ul class="menu-dropdown" @mouseover="listhover = true" @mouseout="listhover = false" >
                <li>{{ this.selected }}<span class="btn-icon">&#9662;</span>
                  <ul v-bind:class="{hover: listhover}">
                    <li v-for="workspace in workspaces" @click="selectWorkspace(workspace.name, workspace.term_id)">{{ workspace.name }}</li>
                  </ul>
                </li>
              </ul> -->
              <dropdown :menuitems="workspaces" :selected="workspace.name" callback="selectWorkspace"></dropdown>
            </div>
         </div>
        </section>
		
</template>

<script>
import globalMethods from '../GlobalMethods.js'
import eventHub from '../EventHub.js'
export default {
  name: 'browseheader',
  data () {
    return {
      workspaces: JSON.parse(localStorage.getItem('workspaces')),
      workspace: JSON.parse(localStorage.getItem('workspace')) || {name: ''},
      listhover: false
    }
  },
  methods: {
    selectWorkspace (workspace) {
      // console.log('selectWorkspace')
      this.workspace = workspace
      this.listhover = false
      localStorage.setItem('workspace', JSON.stringify(workspace))
      eventHub.$emit('workspace-selected', this.workspace.term_id)
    },
    getWorkspaces () {
      var email = JSON.parse(localStorage.getItem('userToken')).user_email
      var self = this
      eventHub.$emit('show-loader', true)
      globalMethods.getData('/wp-json/metro/v1/getuserworkspaces', {user: email})
      .then(function (response) {
        var result = JSON.parse(response.data)
        localStorage.setItem('workspaces', response.data)
        self.workspaces = result
        var match = false
        if (typeof self.workspace !== 'undefined') {
          for (var i = 0; i < result.length; i++) {
            if (parseInt(result[i].term_id) === parseInt(self.workspace.term_id)) {
              console.log(result[i].term_id + self.workspace.term_id)
              self.selectWorkspace(result[i])
              match = true
              break
            }
          }
        }
        if (match === false) {
          self.workspace = result[0]
          localStorage.setItem('workspace', JSON.stringify(result[0]))
          self.selectWorkspace(result[0])
        }
      })
    }
  },
  mounted: function () {
    this.getWorkspaces()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
