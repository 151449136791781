<template>
  
	<div v-show="isActive" class="modal">
		<div class="loader"><span class="icon-spin2 animate-spin"></span></div>
	</div>
		
</template>

<script>
import eventHub from '../EventHub.js'
export default {
  name: 'loader',
  data () {
    return {
      isActive: false,
      loading: 0
    }
  },
  methods: {
    hideLoader () {
      eventHub.$emit('hide-loader')
    }
  },
  mounted: function () {
    eventHub.$on('show-loader', (nocount) => {
      if (nocount !== true) {
        this.loading++
      }
      this.isActive = true
    })
    eventHub.$on('hide-loader', (nocount) => {
      if (nocount !== true) {
        this.loading--
      }
      if (this.loading <= 0) {
        this.isActive = false
      }
      // console.log(this.loading)
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
