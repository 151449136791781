<template>

        <div v-show="isActive" class="main-panel-content tab-panel">
          
          <div class="template-list">
            <table class="template-list-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody v-if="!noemail">
                
                <router-link tag="tr" :to="{ name: 'Compose', params: { user: username, workspace: workspace, email: file.id }}" v-for="file in files" :key="file.id">
                  <td>{{ file.title }}</td>
                  <td>{{ file.date }}</td>
                  <td>{{ file.author }}</td>
                  <td>{{ file.category.name }}</td>
                </router-link>
                
              </tbody>
            </table>
          </div>
        </div>
 
</template>

<script>
import eventHub from '../EventHub.js'
import globalMethods from '../GlobalMethods.js'
export default {
  name: 'filelist',
  data () {
    return {
      files: [{id: '12345', title: 'title', date: 'date', author: 'author', category: {name: 'test'}}],
      isActive: true,
      noemail: false,
      name: 'filelist',
      username: this.$route.params.user,
      emailid: 12345,
      workspace: 'test'
    }
  },
  methods: {
    getFolderEmails (folderid) {
      var self = this
      eventHub.$emit('show-loader')
      this.workspace = JSON.parse(localStorage.getItem('workspace')).slug
      globalMethods.getData('/wp-json/metro/v1/getfolderemails', {folder: folderid})
      .then(function (response) {
        var result = JSON.parse(response.data)
        if (result[0] != null) {
          self.noemail = false
          self.files = result
          // console.log(result)
        } else {
          self.noemail = true
        }
        eventHub.$emit('hide-loader')
      })
    }
  },
  mounted: function () {
    eventHub.$on('tab-selected', (thistab) => {
      if (thistab === this.name) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    })
    eventHub.$on('folder-selected', (folder) => {
      this.getFolderEmails(folder)
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
