<template>
  
  <div v-show="isActive" id="text-editor" class="main-panel-editor main-panel-tab">
    <editor v-model="textdata" width="100%" height="100%" lang="html" @init="initEditor"></editor>
  </div>
    
</template>

<script>
import eventHub from '../EventHub'
export default {
  name: 'texteditor',
  data () {
    return {
      textEditor: Object,
      panel: 'texteditor',
      isActive: false,
      textdata: '',
      emailData: {}
    }
  },
  methods: {
    initEditor () {
      require('brace/mode/html')
      require('brace/theme/dreamweaver')
      require('brace/theme/chrome')
    },
    showPanel (thispanel) {
      if (thispanel.select === this.panel) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      eventHub.$emit('deselect-module')
    }
  },
  watch: {
    textdata: function () {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.emailData.textversion = this.textdata
      localStorage.setItem('emailData', JSON.stringify(this.emailData))
    }
  },
  mounted: function () {
    eventHub.$on('panel-selected', (thistab) => {
      this.showPanel(thistab)
    })
    eventHub.$on('emaildata-loaded', () => {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.textdata = this.emailData.textversion
    })
    eventHub.$on('emaildata-updated', () => {
      this.emailData = JSON.parse(localStorage.getItem('emailData'))
      this.textdata = this.emailData.textversion
    })
  },
  beforeDestroy: function () {
    eventHub.$off()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
