<template>
  
	<div id="modalwrap" class="modal-wrap cf">
			<div class="modal-header">Message</div>
			<div class="modal-content">{{ message }}</div>
		</div>
		
</template>

<script>
// import eventHub from '../EventHub.js'
export default {
  name: 'modalmessage',
  data () {
    return {
      message: 'message'
    }
  },
  methods: {
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
