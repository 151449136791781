import { render, staticRenderFns } from "./Compose.PanelHTML.vue?vue&type=template&id=2b8086d9&scoped=true&"
import script from "./Compose.PanelHTML.vue?vue&type=script&lang=js&"
export * from "./Compose.PanelHTML.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8086d9",
  null
  
)

export default component.exports