<template>
  
    <ul class="menu-dropdown" @click="listhover = !listhover" @mouseleave="listhover = false" >
      <li>{{ selecteditem }}<span class="btn-icon">&#9662;</span>
        <ul v-bind:class="{hover: listhover}">
          <li v-for="menuitem in menuitems" :key="menuitem.id" v-bind:class="{'selected-item': (selecteditem == menuitem.name)}" @click="selectItem(menuitem)">{{ menuitem.name }}</li>
        </ul>
      </li>
    </ul>
    
</template>

<script>
// import globalMethods from '../GlobalMethods.js'
import eventHub from '../EventHub.js'
export default {
  name: 'dropdown',
  props: ['menuitems', 'selected', 'labeled', 'callback', 'emitter'],
  data () {
    return {
      selecteditem: this.selected,
      listhover: false
    }
  },
  methods: {
    selectItem (menuitem) {
      if (this.selecteditem !== menuitem.name) {
        if (this.labeled !== true) {
          this.selecteditem = menuitem.name
        }
        // this.listhover = false
        if (typeof this.callback !== 'undefined' && this.callback !== '') {
          this.$parent.$options.methods[this.callback](menuitem)
        }
        if (typeof this.emitter !== 'undefined' && this.emitter !== '') {
          eventHub.$emit(this.emitter, menuitem)
        }
      }
    }
  },
  watch: {
    selected: function (val) {
      this.selecteditem = val
      if (this.selecteditem === '' || this.selecteditem === null) {
        this.selecteditem = 'Choose'
      }
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
